.AppChrome {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
}

.AppChrome-logo {
  height: 30px;
}

.AppChrome-header {
  background-color: #dbadc2;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: black;
}

.AppChrome-header > * {
  margin-right: 10px;
}

.App-link {
  color: #61dafb;
}

.AppChrome-main {
  padding-top: 50px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  min-height: 0;
}


